<template>
  <div>
    <component-title
      class="not-printable-area"
      :last-breadcrumb="patientName | formatDisplayName"
      :printable="!isCreatingAppointment"
    />

    <loading-patient-profile v-if="isLoadingPatientData" />

    <loading-patient-profile-history v-if="isLoadingPatientData" />

    <Transition name="fade">
      <patient-profile
        v-if="!isCreatingAppointment"
        :patient="patient"
        @setLoading="setLoading"
        @toggleSmartAgenda="openSmartAgenda"
      />
      <smart-agenda
        v-else
        @toggleSmartAgenda="closeSmartAgenda"
      />
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  defaultTo,
  equals,
  not,
  has,
} from 'ramda'

export default {
  name: 'PatientProfilePage',
  components: {
    LoadingPatientProfile: () => import('@/components/loaders/LoadingPatientProfile'),
    LoadingPatientProfileHistory: () => import('@/components/loaders/LoadingPatientProfileHistory'),
    ComponentTitle: () => import('@/components/ComponentTitle'),
    PatientProfile: () => import('./PatientProfileContainer'),
    SmartAgenda: () => import('@/components/SmartAgenda'),
  },
  data() {
    return {
      isCreatingAppointment: has('openSmartAgenda', this.$route.query),
    }
  },
  computed: {
    ...mapGetters({
      patient: 'patient/patient',
      isLoadingPatientData: 'patient/isLoadingPatientData',
    }),
    patientName() {
      return defaultTo('', this.patient)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (not(equals('patientProfile', this.$route.name))) return
        this.updatePatientData()
        this.isCreatingAppointment = has('openSmartAgenda', this.$route.query)
      },
    },
  },
  methods: {
    ...mapActions({
      resetSearchValues: 'smartAgenda/resetSearchValues',
      getPatient: 'patient/getPatient',
    }),
    openSmartAgenda() {
      this.$router.push({ path: this.$route.path, query: { openSmartAgenda: null } })
    },
    updatePatientData() {
      const patientId = this.$route.params.id
      this.getPatient(patientId)
    },
    setLoading(value) {
      this.isLoadingPatientData = value
    },
    closeSmartAgenda() {
      this.$router.push({ path: this.$route.path })
      this.resetSearchValues()
    },
  },
}
</script>
