<template>
  <v-autocomplete
    v-model="selectedProfessional"
    item-text="name"
    item-value="id"
    label="Profissional"
    append-icon=""
    return-object
    clearable
    filled
    :items="professionals"
    :search-input.sync="searchField"
    :loading="isLoading"
    :disabled="disabled"
    data-testid="search-professional__filter"
    @click:clear="clearField()"
    @change="emitProfessional(selectedProfessional)"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.profession.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import isNilOrEmpty from '@/utils/dataValidators'
import {
  equals,
  isNil,
  length,
  find,
  propEq,
} from 'ramda'

export default {
  name: 'SearchProfessional',
  props: {
    clinicId: {
      type: String,
      default: null,
    },
    professionalId: {
      type: String,
      default: null,
    },
    specialityId: {
      type: String,
      default: null,
    },
    hasAgenda: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedProfessional: {},
      searchField: null,
    }
  },
  computed: {
    ...mapGetters({
      professionals: 'professional/professionals',
      isLoading: 'professional/loading',
    }),
    isShowingDefault() {
      return !isNilOrEmpty(this.selectedProfessional)
        && equals(this.professionalId, this.selectedProfessional.id)
    },
  },
  watch: {
    searchField(keyword) {
      if (this.isShowingDefault) return

      if (isNil(this.searchField)) this.$emit('input', null)

      this.search(keyword)
    },
  },
  async mounted() {
    await this.listProfessional({
      id: this.professionalId,
      clinicId: this.clinicId,
      specialityId: this.specialityId,
      hasAgenda: this.hasAgenda,
    })

    this.fillProfessional()
  },
  methods: {
    ...mapActions({
      listProfessional: 'professional/listProfessional',
    }),
    search: debounce(function search(keyword) {
      if (this.isKeywordInvalid(keyword)) return

      this.listProfessional({
        name: keyword,
        clinicId: this.clinicId,
        specialityId: this.specialityId,
        hasAgenda: this.hasAgenda,
      })
    }, 500),
    emitProfessional(event) {
      this.$emit('input', event)
    },
    clearField() {
      this.searchField = null
      this.$emit('input', null)
    },
    isKeywordInvalid(keyword) {
      return isNil(keyword)
      || length(keyword) < 3
    },
    fillProfessional() {
      const selectedProfessional = find(propEq(this.professionalId, 'id'), this.professionals)
      if (isNil(selectedProfessional)) return
      this.selectedProfessional = selectedProfessional
    },
  },
}
</script>
